<template>
  <div id="app">
    <header @click="scrollToFooter" class="header" :class="{ 'dark': isDarkMode }">
      <div>
        <img :src="currentSvgSrc" width="60px" height="60px" class="flip-y-axis"
          alt="Illustrated Vector Portrait of Steven Wolpe">
      </div>
      <div class="text">
        <h1>Steven Wolpe</h1>
        <p>AR Creator and Producer</p>
      </div>
    </header>

    <VideoComponent @toggle-logo-svg="changeSvg" @toggle-dark-header="handleDarkHeader" :videos="videoObjs"/>

    <footer ref="footer">
      <img src="https://stevenwolpe.com/swolpe-images/swolpe-profile-pic-v001-opt.png" width="125px" height="136px"
        alt="Portrait Photo of Steven Wolpe">
      <h2><span class="nowrap">Need help?</span> <span class="nowrap">I'm Steven.</span></h2>
      <h3 class="font-500">I make meaningful UXs in <span class="nowrap">Augmented Reality.</span></h3>
      <p>Recent participant in Snapdragon Spaces Pathfinder program and Niantic Mixed Reality Innovation Lab.</p>
      <div>
        <span class="emoji">
          &#128526;
        </span>
        <a href="mailto:sewolpe@gmail.com">sewolpe@gmail.com</a>
        <a href="https://www.linkedin.com/in/stevenwolpe/" rel="noreferrer noopener" target="_blank">
          <span class="hidden">Link to view Steven Wolpe's LinkedIn profile</span>
          <img src="https://stevenwolpe.com/swolpe-images/linkedin-icon-v001.svg" width="28px" height="28px"
          alt="LinkedIn Logo">
        </a>
      </div>
    </footer>
  </div>

</template>

<script>
import VideoComponent from './components/VideoComponent.vue'

export default {
  name: 'App',
  components: {
    VideoComponent
  },
  data() {
    return {
      isDarkMode: false,
      currentSvgSrc: "https://stevenwolpe.com/swolpe-images/steven-wolpe-illo.svg",
      surpriseSvgSrc: "https://stevenwolpe.com/swolpe-images/steven-wolpe-surprise-illo.svg",
      videoObjs: [
        {
          id: 'video-010',
          url: 'https://stevenwolpe.com/swolpe-videos/color-collapse.mp4',
          iconUrl: 'https://stevenwolpe.com/swolpe-images/tiktok-icon-v001.svg',
          iconAlt: 'TikTok Logo',
          posterUrl: 'https://stevenwolpe.com/swolpe-images/color-collapse-poster-v002-opt.jpg',
          name: 'Color Collapse',
          caption: '15 Million Views, Effect of the Week',
          hashes: '#TikTok #Dev #Producer #Creative',
          reqDark: true,
          isMore: false,
        },
        {
          id: 'video-012',
          url: 'https://stevenwolpe.com/swolpe-videos/cardboard-crashers.mp4',
          iconUrl: 'https://stevenwolpe.com/swolpe-images/8th-wall-icon-v001.svg',
          iconAlt: '8th Wall Logo',
          posterUrl: 'https://stevenwolpe.com/swolpe-images/cardboard-crashers-poster-v002-opt.jpg',
          name: 'Cardboard Crashers',
          caption: 'Niantic MR Innovation Lab',
          hashes: '#8thWall #Dev #UX #Producer',
          reqDark: true,
          isMore: false,
        },
        {
          id: 'video-009',
          url: 'https://stevenwolpe.com/swolpe-videos/my-secret-habits.mp4',
          iconUrl: 'https://stevenwolpe.com/swolpe-images/tiktok-icon-v001.svg',
          iconAlt: 'TikTok Logo',
          posterUrl: 'https://stevenwolpe.com/swolpe-images/secret-habits-poster-v002-opt.jpg',
          name: 'My Secret Habits',
          caption: '22 Million Views, 80k Posts',
          hashes: '#TikTok #Dev #Producer #Creative',
          reqDark: true,
          isMore: false,
        },
        {
          id: 'video-011',
          url: 'https://stevenwolpe.com/swolpe-videos/tiktok-comp.mp4',
          iconUrl: 'https://stevenwolpe.com/swolpe-images/tiktok-icon-v001.svg',
          iconAlt: 'TikTok Logo',
          posterUrl: 'https://stevenwolpe.com/swolpe-images/tiktok-comp-poster-v002-opt.jpg',
          name: 'Games and More',
          caption: '50 Million Views, 176k Posts',
          hashes: '#TikTok #Dev #Producer #Creative',
          reqDark: true,
          isMore: false,
        },
        { 
          id: 'video-001',
          url: 'https://stevenwolpe.com/swolpe-videos/cloud-catch.mp4',
          iconUrl: 'https://stevenwolpe.com/swolpe-images/8th-wall-icon-v001.svg',
          iconAlt: '8th Wall Logo',
          posterUrl: 'https://stevenwolpe.com/swolpe-images/cloud-catch-poster-v003-opt.jpg',
          name: 'Cloud Catch',
          caption: 'Sky Effects Challenge Winner',
          hashes: '#8thWall #Dev #Producer #Creative',
          reqDark: false,
          isMore: false,
        },
        { 
          id: 'video-002',
          url: 'https://stevenwolpe.com/swolpe-videos/mouse-house.mp4',
          iconUrl: 'https://stevenwolpe.com/swolpe-images/8th-wall-icon-v001.svg',
          iconAlt: '8th Wall Logo',
          posterUrl: 'https://stevenwolpe.com/swolpe-images/mouse-house-poster-v003-opt.jpg',
          name: 'Mouse House',
          caption: 'Navigation and Character Control',
          hashes: '#8thWall #Dev #Producer',
          reqDark: true,
          isMore: false,
        },
        {
          id: 'video-004',
          url: 'https://stevenwolpe.com/swolpe-videos/gravity-gun.mp4',
          iconUrl: 'https://stevenwolpe.com/swolpe-images/snap-icon-v001.svg',
          iconAlt: 'Snapchat Logo',
          posterUrl: 'https://stevenwolpe.com/swolpe-images/gravity-gun-poster-v003-opt.jpg',
          name: 'Gravity Gun',
          caption: 'Lens Studio Official Template',
          hashes: '#Snap #Dev #UX #Tutorial',
          reqDark: true,
          isMore: false,
        },
        {
          id: 'video-005',
          url: 'https://stevenwolpe.com/swolpe-videos/candy-corn.mp4',
          iconUrl: 'https://stevenwolpe.com/swolpe-images/snap-icon-v001.svg',
          iconAlt: 'Snapchat Logo',
          posterUrl: 'https://stevenwolpe.com/swolpe-images/candy-corn-poster-v003-opt.jpg',
          name: 'Candy Corn Catch',
          caption: '500k Plays in 30 Days',
          hashes: '#Snap #Dev #Producer #Creative',
          reqDark: true,
          isMore: false,
        },
        // {
        //   id: 'video-003',
        //   url: 'https://stevenwolpe.com/swolpe-videos/madison.mp4',
        //   iconUrl: 'https://stevenwolpe.com/swolpe-images/snap-icon-v001.svg',
        //   posterUrl: 'https://stevenwolpe.com/swolpe-images/madison-poster-v001-opt.jpg',
        //   name: 'Madison Sq Park',
        //   caption: 'Artwork Installation in NYC',
        //   hashes: '#Snap #Dev #UX #Creative',
        //   reqDark: true,
        //   isMore: false,
        // },
        // {
        //   id: 'video-006',
        //   url: 'https://stevenwolpe.com/swolpe-videos/google-devfest-web.mp4',
        //   iconUrl: 'https://stevenwolpe.com/swolpe-images/meta-icon-v001.svg',
        //   posterUrl: 'https://stevenwolpe.com/swolpe-images/google-devfest-poster-v001-opt.jpg',
        //   name: 'Google Devfest',
        //   caption: 'Head tracking and Hand Tracking',
        //   hashes: '#Spark #Dev #Producer #Creative',
        //   reqDark: false,
        //   isMore: false,
        // },
        // {
        //   id: 'video-007',
        //   url: 'https://stevenwolpe.com/swolpe-videos/hotd-web.mp4',
        //   iconUrl: 'https://stevenwolpe.com/swolpe-images/snap-icon-v001.svg',
        //   posterUrl: 'https://stevenwolpe.com/swolpe-images/hbo-hotd-poster-v001-opt.jpg',
        //   name: 'House of the Dragon',
        //   caption: 'Snap x HBO Campaign',
        //   hashes: '#Snap #Dev #UX #Creative',
        //   reqDark: false,
        //   isMore: false,
        // },
        // {
        //   id: 'video-008',
        //   url: 'https://stevenwolpe.com/swolpe-videos/skateboard-web.mp4',
        //   iconUrl: 'https://stevenwolpe.com/swolpe-images/meta-icon-v001.svg',
        //   posterUrl: 'https://stevenwolpe.com/swolpe-images/skateboard-poster-v001-opt.jpg',
        //   name: 'Parts Diagrams',
        //   caption: 'Explaining Things with AR',
        //   hashes: '#Spark #Dev #Producer #Creative',
        //   reqDark: false,
        //   isMore: false,
        // },
      ],
    };
  },
  methods: {
    handleDarkHeader(reqDark) {
      this.isDarkMode = reqDark;
      // console.log('this.isDarkMode: ', this.isDarkMode)
    },
    scrollToFooter() {
      this.$refs.footer.scrollIntoView({ behavior: 'smooth' });
    },
    changeSvg() {
      this.currentSvgSrc = this.currentSvgSrc === this.surpriseSvgSrc ? "https://stevenwolpe.com/swolpe-images/steven-wolpe-illo.svg" : this.surpriseSvgSrc;
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.font-500 {
  font-weight: 500;
}
/* .red-hat-font {
  font-family: 'Red Hat Display', sans-serif;
} */
.nowrap {
  white-space: nowrap;
}
.hidden {
  clip-path: inset(100%);
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
body {
  padding: 0;
  margin: 0;
  background: #000;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 10px 0 50px 30px;
  z-index: 1000; 
  color: #ffffff;
  text-align: left;
  overflow: hidden;
  font-family: 'Red Hat Display', sans-serif;
  cursor: pointer;
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: -1;
}

.header.dark::before {
  opacity: 1;
}
.header img {
  width: 72px;
  height: 72px;
  margin-right: 12px;
}
.flip-y-axis {
  transform: scaleX(-1);
}
.header div.text {
  margin-top: -5px;
}
.header h1 {
  margin: 0px;
  font-weight: 700;
}
.header p {
  margin: 0px;
  font-weight: 500;
}
footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;
  background: #f1f1f1;
  font-family: 'Red Hat Display', sans-serif;
}
footer div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  max-width: 280px;
  margin: 10px auto;
  font-size: 1.25rem;
}
footer h3 {
  margin-bottom: 0;
}
footer p {
  max-width: 400px;
}
footer span.emoji {
  font-size: 1.5rem;
}
@media only screen and (min-width: 600px) {
  .header::before {
    opacity: 1;
  }
}
@media only screen and (max-width: 281px) {
  .header {
    padding: 10px 0 50px 10px;
  }
  .header img {
    width: 56px;
    height: 56px;
  }
 .header h1 {
  font-family: 'Red Hat Display', sans-serif;
  font-size: 1.5rem;
 }
 footer div {
  font-size: 1rem;
 }
}
</style>
