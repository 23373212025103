<template>
  <div class="video-container" @scroll="handleScroll">
    <div v-for="(video, index) in videos" :key="index" class="video-item" @mousedown="handleMouseDown" @mouseup="handleMouseUp">
      <video
        :id="video.id"
        ref="videoElements"
        class="responsive-video"
        :poster="video.posterUrl"
        @loadedmetadata="handleMetadata"
        playsinline
        loop
        disableRemotePlayback>
        <!-- Data attribute to hold the video URL -->
        <source :data-src="video.url" type="video/mp4">

        <!-- <source :src="video.url" type="video/mp4"> -->
        Your browser does not support the video tag.
      </video>
      <div class="video-overlay">
        <div class="video-info">
          <div class="name-tag">
            <img :src="video.iconUrl" width="28px" height="28px" :alt="video.iconAlt">
            <h1 class="video-name">{{ video.name }}</h1>
          </div>
          <p class="video-caption">{{ video.caption }}</p>
          <p class="video-hashes">{{ video.hashes }}</p>
        </div>
        <div v-if="video.isMore" class="video-share">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve"
            width="36px" height="36px">
            <path class="st0" d="M68.76,39.26L45.39,61.23c-2.12,2-5.76,0.58-5.76-2.24v-8.53c-10.77-0.79-25.78-2.4-36.19,11.9
            C-1.81,56.45,4,21.96,39.63,21.95v-9.13c0-2.82,3.63-4.23,5.76-2.24l23.36,21.97C70.73,34.4,70.73,37.41,68.76,39.26z"/>
          </svg>
          More
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoComponent',
  props: ['videos'],
  data() {
    return {
      isDragging: false,
      scrollTimeout: null,
      isScrolling: false,
    };
  },
  mounted() {
    this.initLazyLoading();
    this.setVideoContainerHeight();
    window.addEventListener('resize', this.setVideoContainerHeight);
    window.addEventListener('load', this.setVideoContainerHeight);
    this.checkDarkRequirement();
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.setVideoContainerHeight);
    window.removeEventListener('load', this.setVideoContainerHeight);
  },
  methods: {
    setVideoContainerHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    initLazyLoading() {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          const video = entry.target;
          if (entry.isIntersecting) {
            // const video = entry.target;
            const source = video.querySelector('source');
            source.src = source.dataset.src;
            video.load();
            observer.unobserve(video);
            // video.play();
          } else {
            // video.pause();
          }
        });
      }, {
        rootMargin: '0px',
        threshold: 0.1 // Adjust as needed
      });

      this.$refs.videoElements.forEach(video => observer.observe(video));
    },
    handleMetadata() {
      // Adjust video play logic here if needed
    },
    handleVideoClick(videoElement) {
      if (!this.isDragging) {
        if (videoElement.paused) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      }
    },
    handleMouseDown() {
      this.isDragging = false;
    },
    handleMouseUp(event) {
      if (!this.isDragging) {
        this.handleVideoClick(event.target);
      }
    },
    handleScroll() {
      this.fadeOutOverlay();
      if (!this.isScrolling) {
        this.isScrolling = true
        this.$emit('toggle-logo-svg');
        // Reset isScrolling after 'n' seconds of no scrolling
        clearTimeout(this.isScrollingTimeout);
        this.isScrollingTimeout = setTimeout(() => {
          this.$emit('toggle-logo-svg');
          this.isScrolling = false;
        }, 1000); 
      }
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.checkDarkRequirement();
        this.fadeInOverlay();
      }, 150);
    },
    checkDarkRequirement() {
      let reqDark = this.videos.some(video => video.reqDark && this.isVideoInViewport(video));
      this.$emit('toggle-dark-header', reqDark);
    },
    isVideoInViewport(video, tolerance = 100) {
      const videoElement = this.$refs.videoElements.find(v => v.id === video.id);
      if (!videoElement) {
        return false;
      }

      const rect = videoElement.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;
      const windowWidth = window.innerWidth || document.documentElement.clientWidth;

      return (
        rect.top >= -tolerance &&
        rect.left >= -tolerance &&
        rect.bottom <= windowHeight + tolerance &&
        rect.right <= windowWidth + tolerance
      );
    },
    fadeOutOverlay() {
      const overlays = this.$refs.videoElements.map(v => v.nextElementSibling);
      overlays.forEach(overlay => overlay.style.opacity = '0');
    },
    fadeInOverlay() {
      const overlays = this.$refs.videoElements.map(v => v.nextElementSibling);
      overlays.forEach(overlay => overlay.style.opacity = '1');
    }
  },
}
</script>

<style scoped>
.video-container {
  /* display: flex;
  flex-direction: column;
  justify-content: start; */
  align-items: center;
  /* overflow-y: scroll; */
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  height: calc(var(--vh, 1vh) * 100);
  /* height: 100vh; */
  width: 100%;
  color: #ffffff;
}

.responsive-video {
  object-fit: cover;
  width: 100%;  /* Full width to maintain container width */
  height: calc(var(--vh, 1vh) * 100);
  /* height: 100vh; */
}

.video-item {
  /* padding-top: 10px; */
  position: relative;
  height: calc(var(--vh, 1vh) * 100);
  /* height: 100vh; */
  width: 100%; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  scroll-snap-align: start;
  cursor: pointer;
}

.video-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 86px 15px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:10px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  transition: opacity 0.3s ease; /* Adjust duration as needed */
  opacity: 1; /* Start with fully visible overlay */
  font-family: 'Nunito Sans', sans-serif;
}

.video-info {
  text-align: left;
  color: white;
}

.video-name {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
}

.name-tag {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}

.video-caption {
  margin: 10px 0 0;
  font-weight: 400;
}
.video-hashes {
  margin: 2px 0 0;
  font-weight: 600;
  color: #b6b6b6;
}
.video-share {
  display: flex;
  flex-direction: column;
}
.st0 {
  fill:#FFFFFF;
}

/* Media query for screens with a max width of 500px */
/* @media only screen and (min-width: 500px) {
  .responsive-video {
    max-width: 260px;
    height: auto; 
  }
} */

/* Media query for screens wider than 600px */
@media only screen and (min-width: 600px) {
  .responsive-video {
    max-width: 320px;
    height: auto; /* Adjust height accordingly */
  }
  .video-container {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap:30px;
    flex-direction: row; /* Arrange items in a row */
    align-items: stretch; /* Stretch items to fill the container */
    max-width: 1600px;
    /* overflow: visible; */
    height: auto;
    min-height: 100vh; /* Adjust as necessary */
    margin: 0 auto;
    /* overflow-x: scroll; */
    /* overflow-y: hidden; */
  }

  .video-item {
    border-radius: 4px;
    height: auto; /* Adjust height as needed */
    max-width: 320px;
    /* width: 33.33%; */
    scroll-snap-align: center; /* Center align the items for snap */
  }

  .responsive-video {
    height: 100%; /* Adjust video height to fill the item */
    width: auto; /* Adjust video width as needed */
  }
}
</style>
